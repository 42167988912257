import React, { useEffect } from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import Helmet from 'react-helmet'
import SEO from '../components/SEO'
import config from '../utils/siteConfig'

import heroimage from '../../public/heroimg.gif'

import { gsap } from 'gsap'

const Index = ({ data, pageContext }) => {
  const posts = data.allContentfulPost.edges

  useEffect(() => {
    gsap.set('.circles', { transformOrigin: '50% 50%' })
    gsap.from('.circles', { duration: 1, rotation: -540, ease: 'elastic' })
  })

  return (
    <Layout>
      <SEO />
      <Helmet>
        <title>{`${config.siteTitle}`}</title>
      </Helmet>
      <section className="hero hero-main is-medium" style={{"background-image":  `url(${heroimage})`, "background-repeat": "no-repeat", "background-position": "right", "background-size": "auto 90%", "background-color": "#ffe7e0"}}>
        <div className="hero-body">
          <div className="container">
            <h1 className="title">Work effectively with trauma</h1>
            <h2 className="subtitle"><Link to="/training">Training</Link> & <Link to="/1-to-1-sessions">1 to 1 sessions</Link></h2>
            <Link to="/contact" className="button">Contact Me</Link>
          </div>
        </div>
      </section>

      <section className="columns" style={{margin: "0px"}}>
        <section className="column is-6">
          <div className="content">
            <h2 className="title">1 to 1 sessions</h2>
            <p>Becky offers 1:1 Trauma Sensitive Yoga sessions for people who are looking to reconnect with their body.</p>
            <Link to="/1-to-1-sessions">Read more...</Link>
          </div>
        </section>
        <section className="column is-6" style={{background: "#011936"}}>
        <div className="content">
            <h2 className="title" style={{color: "#FFF"}}>Training</h2>
            <p style={{color: "#FFF"}}>Move Through Trauma provides a range of education and support for organisations who are looking to improve their understanding of trauma and their services for trauma survivors.</p>
            <Link to="/training" style={{color: "#FFF", "border-bottom": "2px solid #ffe7e0"}}>Read more...</Link>
          </div>
        </section>
      </section>
      <section className="columns" style={{margin: "0px"}}>
        <section className="column is-half is-offset-one-quarter">
          <div className="container content" style={{"max-width": "600px"}}>
            <h2 className="has-text-centered">About</h2>
            <p>Move Through Trauma provides individual and group yoga sessions for people who have experienced ongoing trauma within relationships and who may have a diagnosis of Complex Post Traumatic Stress Disorder or ‘treatment resistant’ PTSD.</p>
            <Link to="/about">Read more...</Link>

          </div>
        </section>
      </section>
      <section className="columns">
        <section className="column is-half is-offset-one-quarter">
          <div className="container content" style={{"max-width": "600px"}}>
            <h2 id="pricing">Pricing</h2>
            
            <h3>1 to 1 sessions</h3>
            <table className="table is-fullwidth">
              <tbody>
                <tr>
                  <td>Initial 2hr assessment</td>
                  <td>£150</td>
                </tr>
                <tr>
                  <td>1hr session</td>
                  <td>£75</td>
                </tr>
                <tr>
                  <td>1hr session (if receiving benefits)</td>
                  <td>£50</td>
                </tr>
              </tbody>
            </table>
            <h3>Training sessions</h3>
            <p><Link to="/contact">Contact me</Link> to discuss pricing.</p>
          </div>
        </section>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query($limit: Int!) {
    allContentfulPost(
      sort: { fields: [publishDate], order: DESC }
      limit: $limit
    ) {
      edges {
        node {
          title
          id
          slug
          publishDate(formatString: "MMMM DD, YYYY")
          body {
            childMarkdownRemark {
              timeToRead
              html
              excerpt(pruneLength: 80)
            }
          }
        }
      }
    }
  }
`

export default Index
